export const globalReducer = (state = {
  test: true,
  menu: {
    open: false
  }
}, action) => {

  switch(action.type){
    case 'TEST_ACTION':

      return {
        ...state
      }

    case 'TOGGLE_MENU': 

      if(action.data !== undefined){
        return {
          ...state, 
          menu: {
            open: action.data
          }
        }
      } else {
        return {
          ...state,
          menu: {
            open: !state.menu.open
          }
        }
      }
      
    default:
      return state
  }
}