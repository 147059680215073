import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import shortid from 'shortid'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import Button from '../components/Button/Button.js'
import ToolList from '../components/ToolList/ToolList.js'

var tools = require('../tools/_list.js').default;

class Home extends Component {
  constructor(props){
    super(props)

  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;

    return <div className="body-stretch">
      <Header/>
      <div className="content">
        <div className="header-bumper"/>
        <div className="">
          <div className="container">
            <div className="row row-push-down">
              <div className="col-md-8">
                <h1 className="brand-font">
                  <span>not yet digital</span>
                </h1>
                <p className="lead">
                  a software company
                </p>
                <div className="list-left">
                  <a href="mailto:hi@notyetdigital.com" className="list-item">
                    <Button 
                      display={<span><i className="far fa-envelope"/> &nbsp;Get in touch</span>}
                      size="medium"
                      />
                  </a>
                  <span className="list-item">
                    or check out our first product 👇
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <a href="https://pattycake.io" target="_blank">
                <h2 className="text-brand-pattycake">Pattycake</h2>
                <video className="video-screenshot" width="100%" autoPlay muted loop>
                  <source src="/vid/homescreenv01.mp4" type="video/mp4"/>
                  <source src="/vid/homescreenv01.ogv" type="video/ogg"/>
                  <source src="/vid/homescreenv01.webm" type="video/webm"/>
                  Your browser does not support the video tag.
                </video>
                <p>
                  A web-based tool for architects and designers to create and edit a unique and antiquated file format still used to represent materials in architectural drawings.
                </p>
              </a>
            </div>
          </div>
          <hr className="mega"/>
          <div className="row">
            <div class="col-md-12">
              <p className="lead text-center push-down">
                Tired of slow, ad filled webtools for common problems?<br/>
                <span className="text-heavy">Us too.</span> So we create our own from time-to-time and share them here.
              </p>
            </div>
          </div>
          
        </div>
      </div>
      <ToolList minimal={true}/>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(Home);