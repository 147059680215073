import React, { Component } from 'react'
import QRCode from 'qrcode'

import CustomField from '../components/CustomField/CustomField.js';
import Button from '../components/Button/Button.js';
import Checkbox from '../components/Checkbox/Checkbox.js';
import ToolList from '../components/ToolList/ToolList.js';

class Sketch extends Component {

  constructor(props){
    super(props);

    this.state = {
      input_string: "",
    }

    this.handleInputChange = this.handleInputChange.bind(this);
   
  }

  handleInputChange(e){
    let input = e;
    this.setState({
      input_string: e
    })

    let canvas = document.getElementById('output_canvas')
    
     if(e !== ""){
      QRCode.toCanvas(canvas, e, {width: 450}, function (error) {
        if (error) console.error(error)
        console.log('success!');
      })
    }
  }


  render(){

    return (
      <div id="qr-code">
        <div className="header-bumper"/>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>QR code</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CustomField
                type="text"
                name="input-list"
                placeholder="enter data or a URL here to encode it..."
                minRows={15}
                multipleRows={true}
                lockSize={true}
                autoUpdate={true}
                autoFocus={true}
                value={this.state.input_string}
                onChangeEvent={(e) => { this.handleInputChange(e); }}
                description={this.state.input_string !== "" &&<p>Copy or save your QR code by right clicking the image.</p>  }
              />
            </div>
            <div className="col-md-6">
              <canvas 
                className="output_canvas" 
                id="output_canvas" 
                style={{
                  opacity: this.state.input_string !== "" ? 1 : 0
                }}
                />
            </div>
          </div>
        </div>
        <ToolList/>
      </div>
    )
  }
}

export default Sketch;
