import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import './Menu.css';

import { toggleMenu } from '../../actions/actions.export.js'

class Menu extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);
    this.state = {
      open: false
    }

    this.handleMenuToggle = this.handleMenuToggle.bind(this);
  }


  handleMenuToggle(){
    const { globalReducer, dispatch } = this.props;
    dispatch(toggleMenu())
  }

  render(){
    const { globalReducer, dispatch } = this.props;

    return <div id="menu" className={"" + (globalReducer.menu.open ? "menu-open " : "menu-closed ")}>
      <div className="menu-list">
        <Link onClick={this.handleMenuToggle} to="/" className="menu-link menu-item">Home</Link>
        <Link onClick={this.handleMenuToggle} to="/work" className="menu-link menu-item">Work</Link>
        <Link onClick={this.handleMenuToggle} to="/tools" className="menu-link menu-item">Tools</Link>
        <Link onClick={this.handleMenuToggle} to="/contact" className="menu-link menu-item">Contact</Link>
      </div>
    </div>
  }

  compnentWillReceiveProps(newProps){
    this.setState({
      open: newProps.open
    });
  }
}


const mapStateToProps = (state) => {
  const { 
  
    globalReducer
    //...
  } = state;

  return {
    globalReducer
    //...
  }
}

export default connect(mapStateToProps)(Menu);