import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import shortid from 'shortid'

import { toggleMenu } from '../../actions/actions.export.js'

import './Header.css';

class Header extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);

    this.state = {
      translucent: true,
      top: true
    }

    this.handleScroll = this.handleScroll.bind(this);
    this.handleMenuToggle = this.handleMenuToggle.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    if(window.scrollY > 0){
      this.setState({
        top: false
      });
    } else {
      this.setState({
        top: true
      });
    }
  }

  handleMenuToggle(){
    const { globalReducer, dispatch } = this.props;
    dispatch(toggleMenu())
  }


  render(){
    const { dispatch, globalReducer } = this.props; 
    const state = this.state;

    return (
      <div className={"header "
       + (state.top ? "header-top" : "header-scrolling")
       + (this.props.overImage ? " header-overlay" : " header-scrolling")}>
          <div className="flex-split">
            <div className="">
              {
                window.location.pathname !== "/" &&
                <Link to="/" className="brand-font grey-to-black"><i className="fas fa-arrow-alt-left"/> back</Link>
              }
            </div>
            <div>
              <span className="list-item menu-btn" onClick={this.handleMenuToggle} key={shortid.generate()}>
                {/*
                  globalReducer.menu.open ? <i className="fal fa-2x fa-fw fa-times"/> : <i className="fal fa-fw fa-2x fa-bars"/>
                */}
              </span>
            </div>
          </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  const { globalReducer } = state;

  return {
    globalReducer
  }
}

export default connect(mapStateToProps)(Header);
