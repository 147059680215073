import React, { Component } from 'react'
import QRCode from 'qrcode'
import * as d3 from 'd3'


import CustomField from '../components/CustomField/CustomField.js';
import Button from '../components/Button/Button.js';
import Checkbox from '../components/Checkbox/Checkbox.js';
import ToolList from '../components/ToolList/ToolList.js';

var svgsaver = require('save-svg-as-png');

class Sketch extends Component {

  constructor(props){
    super(props);

    this.state = {
      input_string: "",
    }

    this.handleInputChange = this.handleInputChange.bind(this);
   
  }

  handleSave(){
    svgsaver.saveSvgAsPng(document.getElementById("bar-chart-svg"), "diagram.png", {scale: 3});
  }

  handleInputChange(e){
    
    var input = e.split(/[\,\n\r]/);

    let rows = e.split(/[\n\r]/g);
    let mult_data = [];
    let headers = [];
    let min = 0;
    let max = 0;
    let max_cols = 1;
    for(let i in rows){
      let cols = rows[i].split(/\,/g);

      // check to see if this is headers
      if(i == 0){
        let alpha = false;
        for(let j in cols){
          if(isNaN(cols[j]) && cols[j] !== ""){
            alpha = true;
            break;
          }
        }
        if(alpha){
          headers = cols;
          continue;
        }
      }

      for(let j in cols){
        if(cols[j] == "") continue;
        if(cols.length > max_cols) max_cols = cols.length;
        let t = parseFloat(cols[j]);
       
        if(t > max) max = t;
        if(t < min) min = t;
        mult_data.push({
          value: t, 
          label: headers[j],
          index: parseInt(j)
        })
      }
    }

    // console.log('minmax', min, max);

    let colors = [
      '#053C5E',
      '#F2A359',
      '#A31621',
      '#00A878',
      '#632549'
    ]

    for(var i = 0; i < input.length; i++){
      input[i] = input[i].trim();
      if(input[i] === ""){
        input.splice(i, 1);
        i--;
      }
    }
    for(var i in input){
      input[i] = parseFloat(input[i]);
    }

    let data = input;

    let el = document.getElementById('bar-chart-svg');

    let width = el.clientWidth;
    let height = el.clientHeight;

    let minDim = d3.min([width, height]);

    let svg = d3.select('#bar-chart-svg')
      .attr('width', width)
      .attr('height', height)
      ;

    svg.selectAll('rect').remove();
    svg.selectAll('text').remove();
    svg.selectAll('axis').remove();
    svg.selectAll('path').remove();
    svg.selectAll('line').remove();
    svg.selectAll('g').remove();

    var labelFill = '#555';
    var gridLineStroke = '#ddd';

    var padding = {
      top: 50,
      bottom: 40,
      left: 0,
      right: 0
    };

    if(mult_data.length > 10){
      padding.left = 50;
    }

    
    var group_spacing = 20;
    var bar_spacing = group_spacing / 4;

    var bar_w = (width - padding.left - padding.right - bar_spacing * mult_data.length - group_spacing * mult_data.length / max_cols) / mult_data.length;
    while(group_spacing >= bar_w / 2 - 1){
      group_spacing--;
      bar_spacing = group_spacing / 4;
      bar_w = (width - padding.left - padding.right - bar_spacing * mult_data.length - group_spacing * mult_data.length / max_cols) / mult_data.length;
    }

    var y_scale = d3.scaleLinear()
      .domain([min, max])
      .range([height - padding.bottom, padding.top])
      .nice()
      ;

    var color_scale = d3.scaleLinear()
      .domain([min, 0, max])
      .range(['#CA193C', '#5D6F7F', '#5BA5E6'])
      ;

    if(mult_data.length > 10){
      let y_axis = d3.axisLeft(y_scale)
        .ticks(10)
        .tickSize(width - padding.left - padding.right + bar_spacing)
        ;

      svg.append("g")
        .attr("transform", "translate(" + (width - padding.right) + "," + 0 + ")")
        .call(y_axis);

      d3.selectAll('path').attr('opacity', 0);
      d3.selectAll('.tick').selectAll('line').attr('opacity', .25);
      d3.selectAll('.tick').selectAll('text').attr('font-size', 12);

      svg.append('line')
        .attr('x1', padding.left)
        .attr('y1', y_scale(0))
        .attr('x2', width - padding.right)
        .attr('y2', y_scale(0))
        .attr('stroke','#000')
        .attr('stroke-width', 2)
        ;
    }

    var barsvg = svg.selectAll('rects')
      .data(mult_data)
      .enter()
      ;
    
    barsvg.append('rect')
      .attr('x', function(d, i){ 
        let group = Math.floor(parseInt(i) / max_cols + 1);
        return padding.left + i * (bar_spacing + bar_w) + group * group_spacing; 
      })
      .attr('y', function(d){ 
        if(d.value < 0) return y_scale(0);
        return y_scale(d.value); 
      })
      .attr('width', bar_w)
      .attr('height', function(d){ 
        
        if(d.value < 0) return y_scale(d.value) - y_scale(0);
        return y_scale(0) - y_scale(d.value); 
      })
      .attr('fill', function(d){ 
        if(max_cols && headers.length > 0){
          return colors[d.index];
        }
        return color_scale(d.value); 
      })
      .attr('class', 'clickable-element')
      ;
    var rotate_text = false;
    if(mult_data.length <= 10){
      barsvg.append('text')
        .attr('class', 'bar_label')
        .text(function(d){ return d.value.toLocaleString(); })
        .attr('text-anchor', 'middle')
        .attr('x', function(d, i){ 
          let group = Math.floor(parseInt(i) / max_cols + 1);
          return padding.left + i * (bar_spacing + bar_w) + group * group_spacing + bar_w/2; 
        })
       .attr('y', function(d){ 
          if(d.value < 0) return y_scale(0) - 5;
          return y_scale(d.value) - 5; })
        .attr('font-size', 14)
        .attr('fill', labelFill)
        .style('font-weight', 700)
        .attr('class', 'clickable-element')
        .each(function(){
          if(this.getComputedTextLength() > bar_w){
            rotate_text = true;
          }
        })
        ;
    } else {
      svg.append('line')
        .attr('x1', padding.left)
        .attr('y1', y_scale(0))
        .attr('x2', width - padding.right)
        .attr('y2', y_scale(0))
        .attr('stroke','#000')
        .attr('stroke-width', 2)
        ;
    }


    if(rotate_text){
      svg.selectAll('.bar_label')
        .attr('text-anchor', 'start')
        .attr('x', 0)
        .attr('y', 7)
        .attr('font-size', 16)
        .attr('transform', function(d, i){
          return 'translate(' + (padding.left + i * (bar_spacing + bar_w) + bar_w / 2) + ',' + (height - padding.bottom - y_scale(d.value) - 5) + '),rotate(270)';
        })
        ;
    }

    this.setState({
      input_string: e
    })

  }


  render(){

    return (
      <div id="bar-chart">
        <div className="header-bumper"/>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>bar chart</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CustomField
                type="text"
                name="input-list"
                placeholder="enter comma or line separated numeric data"
                minRows={15}
                multipleRows={true}
                lockSize={true}
                autoUpdate={true}
                autoFocus={true}
                value={this.state.input_string}
                onChangeEvent={(e) => { this.handleInputChange(e); }}
              />
            </div>
            <div className="col-md-6">
              <svg id="bar-chart-svg" className="tool-output-svg"/>
              <div className="flex-split">
                <div className="list-item">
                  
                </div>
                <div className="list-item">
                  {
                    this.state.input_string !== "" &&
                    <Button 
                      display={<span><i className="fas fa-download fa-lg"/>&nbsp;&nbsp;Download </span>}
                      size="medium"
                      onClick={this.handleSave}
                      />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToolList/>
      </div>
    )
  }
}

export default Sketch;
