import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux';

import './ToolManager.css';

var tools = require('../../tools/_list.js').default;
var Tool;

class ToolManager extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);

    this.loadTool = this.loadTool.bind(this);
  }

  componentWillMount(){
    this.loadTool(this.props);
  }

  loadTool(props){
    const { dispatch } = this.props;

    
    var tool_id = props.tool;

    var found = false;
    for(var i in tools){
      if(tools[i].id === tool_id){
        found = true;
        break;
      }
    }

    if(!found && tool_id != 'random' && tool_id != 'latest' && tool_id){
      try{
        Tool = require('../../tools/' + tool_id + '.js').default;
      } catch(e){
        console.error(e);
        Tool = require('../../tools/' + tools[0].id + '.js').default;
        return dispatch(push('/tools')); 
      }
    } 

    try{
      Tool = require('../../tools/' + tool_id + '.js').default;
    } catch(e){
      console.error(e);
      Tool = require('../../tools/' + tools[0].id + '.js').default;
      return dispatch(push('/tools')); 
    }
  }

  componentWillReceiveProps(newprops){
    this.loadTool(newprops);
  }

  render(){

    return (
      <div className="tool-manager">
        <Tool/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  // const { user } = state;
  return {
    // user
  }
}

export default connect(mapStateToProps)(ToolManager);
