const spacing = 12;

export const tooltip = (state = {
  show: false,
  content: '',
  d: {}
}, action) => {

  switch(action.type){
    case 'SHOW_TOOLTIP':
      var target = action.data.e.target;

      var d = action.data.d;

      var top, bottom, left, right;

      if(action.data.position === 'top' || action.data.position === 'bottom'){
        if(d.x + d.width > window.innerWidth){
          action.data.position = 'left';
        } else if(d.x < 0){
          action.data.position = 'right';
        }
      }

      switch(action.data.position){
        case 'bottom':
          left = d.x + d.width / 2;
          top = d.y + d.height + spacing;
          break;
        case 'top':
          left = d.x + d.width / 2;
          bottom = window.innerHeight - d.y + spacing;
          break;
        case 'left':
          left = d.x - spacing;
          top = d.y - d.height / 2;
          break;
        case 'right':
          left = d.x + d.width + spacing;
          top = d.y - d.height / 2;
          break;
      }

      return {
        ...state,
        show: true,
        content: action.data.content,
        position: action.data.position,
        d: d,
        nobr: action.data.nobr,
        top: top,
        bottom: bottom,
        left: left,
        right: right
      }

    case 'HIDE_TOOLTIP':
      return {
        ...state,
        show: false,
        // content: ''
      }
        
    default:
      return state
  }
}