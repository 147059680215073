import React, { Component } from 'react'

import './Checkbox.css';

class Checkbox extends Component {
  constructor(props){
    super(props);

    this.state = {
      value: props.value,
      onToggle: props.onToggle 
    }
  }

  componentWillReceiveProps(newProps){
    this.setState({
      value: newProps.value,
      onToggle: newProps.onToggle
    })
  }

  render(){    
    return this.state.value ? 
    (
      <span className="checkbox checkbox-true" key={'true-check'} onClick={this.state.onToggle}>
        <i className={"fas fa-fw fa-lg "  + ( this.props.switch ? "fa-toggle-on" : "fa-check-square") }></i>
      </span>
    ) :
    (
      <span className="checkbox checkbox-false" key={'false-check'} onClick={this.state.onToggle}>
        <i className={"fas fa-rotate-180 fa-fw fa-lg "  + ( this.props.switch ? "fa-toggle-on" : "fa-square") }></i>
      </span>
    )
  }
};

export default Checkbox;