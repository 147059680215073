import React, { Component } from 'react'

import CustomField from '../components/CustomField/CustomField.js';
import Button from '../components/Button/Button.js';
import ToolList from '../components/ToolList/ToolList.js';

class Sketch extends Component {

  constructor(props){
    super(props);

    this.state = {
      input_string: "",
      input: [],
      output: [],
      autorandom: true,
    }

    this.handleListChange = this.handleListChange.bind(this);
    this.randomize = this.randomize.bind(this);
  }

  shuffle(array) {
    var m = array.length, t, i;

    // While there remain elements to shuffle…
    while (m) {

      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  }

  handleListChange(e){

    var input = e.split(/[\,\n\r]/);

    for(var i = 0; i < input.length; i++){
      input[i] = input[i].trim();
      if(input[i] === ""){
        input.splice(i, 1);
        i--;
      }
    }

    this.setState({
      input_string: e
    });

    this.randomize(null, input);
  }

  randomize(e, input){

    if(!input) input = this.state.input;
    
    var output = this.shuffle(input);
    var output_string = output.join('\n');

    this.setState({
      input: input,
      output: output,
      output_string: output_string
    })
  }

  render(){

    return (
      <div id="list-randomizer">
        <div className="header-bumper"/>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>list randomizer</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CustomField
                type="text"
                name="input-list"
                placeholder="enter a comma or line separated list here..."
                minRows={15}
                multipleRows={true}
                lockSize={true}
                autoUpdate={true}
                value={this.state.input_string}
                onChangeEvent={(e) => { this.handleListChange(e); }}
                autoFocus={true}
              />
              <div className="flex-split">
                <div className="list-item">
                  
                </div>
                <div className="list-item">
                  <Button 
                    display={<span><i className="fas fa-random fa-lg"/>&nbsp;&nbsp;Randomize </span>}
                    size="medium"
                    onClick={this.randomize}
                    />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <CustomField
                type="text"
                name="input-list"
                placeholder="...and your randomized list will appear here"
                minRows={15}
                multipleRows={true}
                lockSize={true}
                autoUpdate={true}
                value={this.state.output_string}
              />
            </div>
          </div>
        </div>
        <ToolList/>
      </div>
    )
  }
}

export default Sketch;
