import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router'
import { ConnectedRouter } from 'react-router-redux'

import ScrollToTop from './ScrollToTop.js'

import Home from './routes/Home.js'
import ToolFullScreen from './routes/ToolFullScreen.js'
import ToolRoute from './routes/ToolRoute.js'
import Contact from './routes/Contact.js'

import Menu from './components/Menu/Menu.js'
import Tooltip from './components/Tooltip/Tooltip.js'

import { toggleMenu } from './actions/actions.export.js'


class App extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);
    this.handleMenuClose = this.handleMenuClose.bind(this);
  }

  handleMenuClose(){
    const {globalReducer, dispatch} = this.props;
    if(globalReducer.menu.open) dispatch(toggleMenu(false));
  }

  componentDidMount(){

  }

  render(){
    const { history } = this.props;

    return <ConnectedRouter history={history}>
      <ScrollToTop>
        <div className="body">
          <Tooltip/>
          <Menu/>
          <div className="body-wrapper" onClick={this.handleMenuClose}>
            <Switch>
              <Route exact path="/" component={Home}/>
                <Route path="/tools/:tool_id" component={ToolFullScreen} />
                
                  <Route path="/tools" component={ToolRoute} />
                  {/*
                  <Route path="/work.with.us" component={Contact} />
                */}
              <Redirect to='/' />
            </Switch>
          </div>
        </div>
      </ScrollToTop>
    </ConnectedRouter>
  }
}

const mapStateToProps = (state) => {
  const { 
    globalReducer
    // nav
    //...
  } = state;

  return {
    globalReducer
    // nav
    //...
  }
}

export default connect(mapStateToProps)(App);