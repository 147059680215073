import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

var tools = require('../../tools/_list.js').default;

class ToolList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);
  }

  render(){
    const { dispatch, user } = this.props; 
    const state = this.state;

    return (
      <div className="container">
        {
          !this.props.minimal && 
          <div>
            <hr className="mega"/>
            <div className="row">
              <div className="col-md-12">
                <p className="lead text-center push-down">
                  More tools 👇
                </p>
              </div>
            </div>
          </div>
        }
        <div className="row">
          {
            tools.map((t, i)=>{
              return <div className="col-md-4" key={i}>
                <Link to={"/tools/" + t.id} className="tool-link">
                  <h4>{t.id}</h4>
                  <p>{t.description}</p>
                </Link>
              </div>
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  // const { user } = state;

  return {
    // user
  }
}

export default connect(mapStateToProps)(ToolList);
