import { combineReducers } from 'redux'
import { routerReducer as routing } from 'react-router-redux';
import { globalReducer } from './reducers.global.js';
import { tooltip } from './reducers.tooltip.js';

import { reducer as formReducer } from 'redux-form'

const rootReducer = combineReducers({
  routing,
  globalReducer,
  form: formReducer,
  tooltip

  // ...
})

export default rootReducer
