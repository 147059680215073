import React, { Component } from 'react'

import CustomField from '../components/CustomField/CustomField.js';
import Button from '../components/Button/Button.js';
import Checkbox from '../components/Checkbox/Checkbox.js';
import ToolList from '../components/ToolList/ToolList.js';

class Sketch extends Component {

  constructor(props){
    super(props);

    this.state = {
      input_string: "",
      output: {
        characters: 0,
        words: 0,
        sentences: 0
      }
    }

    this.handleInputChange = this.handleInputChange.bind(this);
   
  }

  handleInputChange(e){
    let input = e.trim();

    let char = input.length;

    let w = input.split(/\s/)
    let words = w.length;
    if(w[0] == "") words--;

    let s = input.split(/\./);
    let sentences = s.length;
    if(s[s.length - 1] == "") sentences--;
    
    this.setState({
      input_string: e,
      output: {
        characters: char,
        words: words,
        sentences: sentences,
      } 
    })
  }

  render(){

    return (
      <div id="uri-encoder">
        <div className="header-bumper"/>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>character count</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CustomField
                type="text"
                name="input-list"
                placeholder="enter text here..."
                minRows={15}
                multipleRows={true}
                lockSize={true}
                autoUpdate={true}
                value={this.state.input_string}
                onChangeEvent={(e) => { this.handleInputChange(e); }}
                autoFocus={true}
              />
            </div>
            <div className="col-md-6 align-col-with-input">
              <h3>{this.state.output.characters} characters</h3>
              <h3>{this.state.output.words} words</h3>
              <h3>{this.state.output.sentences} sentences</h3>
            </div>
          </div>
        </div>
        <ToolList/>
      </div>
    )
  }
}

export default Sketch;
