import React, { Component } from 'react'

import CustomField from '../components/CustomField/CustomField.js';
import Button from '../components/Button/Button.js';
import Checkbox from '../components/Checkbox/Checkbox.js';
import ToolList from '../components/ToolList/ToolList.js';

class Sketch extends Component {

  constructor(props){
    super(props);

    this.state = {
      input_string_a: "",
      input_a: [],
      input_string_b: "",
      input_b: [],
      output: [],
      list_longer: false,
      autorandom: true,
    }

    this.handleListChange = this.handleListChange.bind(this);
    this.randomize = this.randomize.bind(this);
  }

  shuffle(array) {
    var m = array.length, t, i;

    // While there remain elements to shuffle…
    while (m) {

      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return JSON.parse(JSON.stringify(array));
  }

  handleListChange(e, l){

    var input = e.split(/[\,\n\r]/);

    for(var i = 0; i < input.length; i++){
      input[i] = input[i].trim();
      if(input[i] === ""){
        input.splice(i, 1);
        i--;
      }
    }

    this.setState({
      ['input_string_' + l]: e,
      ['input_' + l]: input
    });

    if(this.state.autorandom){
      if(l === 'a'){
        this.randomize(null, input);
      } else {
        this.randomize(null, null, input);
      }
    }
  }

  randomize(e, input_a, input_b){

    if(!input_a) input_a = this.state.input_a;
    if(!input_b) input_b = this.state.input_b;
    
    var input_a = this.shuffle(input_a);
    var input_b = this.shuffle(input_b);

    var normalized_a = [];
    var normalized_b = [];

    var extra_list = [];
    var list_longer = false;
    for(var i = 0; i < Math.max(input_a.length, input_b.length); i++){
      var a = input_a[i % input_a.length];
      var b = input_b[i % input_b.length];

      if(a === undefined) a = "";
      if(b === undefined) b = "";

      if(i >= input_b.length &&  i >= Math.floor(input_a.length / input_b.length) * input_b.length && input_a.length % input_b.length != 0){
        list_longer = 'a';
        extra_list.push(b);
      } else if(i >= input_a.length && i >= Math.floor(input_b.length / input_a.length) * input_a.length && input_b.length % input_a.length != 0){
        list_longer = 'b';
        extra_list.push(a)
      }

      normalized_a.push(a + "");
      normalized_b.push(b + "");
    }

    extra_list.sort(function(a,b){
      if(a > b) return 1;
      if(a < b) return -1;
      return 0;
    })

    normalized_a = this.shuffle(normalized_a);
    normalized_b = this.shuffle(normalized_b);

    var output = [];
    var output_string = "";
    for(var i = 0; i < normalized_a.length; i++){
      output.push({
        a: normalized_a[i],
        b: normalized_b[i]
      })

      output_string += normalized_a[i] + '\t' + normalized_b[i]

      if(i < normalized_a.length - 1){
        output_string += '\n';
      }
    }

    output_string.trim();

    this.setState({
      input_a: input_a,
      input_b: input_b,
      output: output,
      output_string: output_string,
      extra_list: extra_list,
      list_longer: list_longer
    })
  }

  render(){

    return (
      <div id="random-pairs">
        <div className="header-bumper"/>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>random pairs</h2>
            </div>
          </div>
          <div className="row row-push-down">
            <div className="col-md-3">
              <CustomField
                type="text"
                name="input-list"
                placeholder="list A..."
                description={<span>
                  This list has <strong>{this.state.input_a.length}</strong> items.
                </span>}
                minRows={15}
                multipleRows={true}
                lockSize={true}
                autoUpdate={true}
                value={this.state.input_string_a}
                onChangeEvent={(e) => { this.handleListChange(e, 'a'); }}
                autoFocus={true}
              />
            </div>
            <div className="col-md-3">
              <CustomField
                type="text"
                name="input-list"
                placeholder="list B..."
                description={<span>
                  This list has <strong>{this.state.input_b.length}</strong> items.
                </span>}
                minRows={15}
                lockSize={true}
                multipleRows={true}
                autoUpdate={true}
                value={this.state.input_string_b}
                onChangeEvent={(e) => { this.handleListChange(e, 'b'); }}
              />
            </div>
            <div className="col-md-6">
              <CustomField
                type="text"
                name="input-list"
                placeholder="...your randomized pairs will appear here"
                description={
                  this.state.list_longer ?
                  <span>
                    Since the lists aren't evenly divisble, the following were randomly assigned an extra pairing:<br/>
                    {
                      this.state.extra_list.map((e, i) => {
                        return <span key={i}>
                          <strong>{e}</strong>
                          {
                            (i < this.state.extra_list.length - 1) && ", "
                          }
                        </span>
                      })
                    }
                  </span>
                  :
                  <span>
                    {
                      (this.state.input_a.length > 0 || this.state.input_b.length > 0) && "Lists are evenly divisible 👍"
                    }
                  </span>
                }
                minRows={15}
                multipleRows={true}
                lockSize={true}
                autoUpdate={true}
                value={this.state.output_string}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="flex-split">
                <div className="list-item">
                  <Checkbox value={this.state.autorandom} onToggle={(e)=>{ this.setState({autorandom: !this.state.autorandom}) }} switch={true}/> Autorandomize
                </div>
                <div className="list-item">
                  <Button 
                    display={<span><i className="fas fa-random fa-lg"/>&nbsp;&nbsp;Randomize </span>}
                    size="medium"
                    onClick={this.randomize}
                    />
                </div>
              </div>
            </div>
            <div className="col-md-6">
            </div>
          </div>
        </div>
        <ToolList/>
      </div>
    )
  }
}

export default Sketch;
