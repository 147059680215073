// Load React and Redux
import 'bootstrap';
import React from 'react'
import { render } from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'

// Prep browser history 
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux'

// Create a logger and middleware
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'

// Import reducers
import reducers from './reducers/reducers.export.js'
import App from './App.js'

// Grab bootstrap in an annoying way
import '../node_modules/bootstrap/dist/css/bootstrap.css'

// Load base styling
import './styles/index.css'

// Once we are in a stable place and want some caching/progressive-web-app style, turn this on:
// import registerServiceWorker from './registerServiceWorker';

// Create an array of middleware - in this case of length 1
const middleware = [ thunk ]

// If we aren't in production add a logger to the middleware array
if(process.env.NODE_ENV !== 'production'){
  // log everything except redux-form because hybrid crashes that hard.
  middleware.push(createLogger({
    predicate: (getState, action) => action.type.indexOf('@@redux-form') === -1
  }));

}

// Create empty browser history
var history = createBrowserHistory();

// Create the store from reducers and embed the middleware
const store = createStore(reducers, applyMiddleware(...middleware, routerMiddleware(history)))

// Render the DOM
render(
  <Provider store={store}>
    <App history={history}/>
  </Provider>,
  document.getElementById('root')
)

// // Once we are in a stable place and want some caching/progressive-web-app style, turn this on:
// if(process.env.NODE_ENV === 'production'){
//   registerServiceWorker();
// }