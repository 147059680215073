var tooltipTimeout;


export const showTooltipNow = data => ({
  type: 'SHOW_TOOLTIP',
  data
})


export const showTooltip = data => dispatch => {
  data.e.persist();
  data.d = data.e.target.getBoundingClientRect();
  data.e.stopPropagation();
  data.e.preventDefault();

  clearTimeout(tooltipTimeout);
  if(data.lag){
    tooltipTimeout = setTimeout(function(){

      dispatch(showTooltipNow(data));
    }, data.lag)
  } else {
    dispatch(showTooltipNow(data));
  }
}

export const hideTooltipNow = data => ({
  type: 'HIDE_TOOLTIP',
  data
})


export const hideTooltip = data => dispatch => {
  clearTimeout(tooltipTimeout);
  dispatch(hideTooltipNow());
}
