import React, { Component } from 'react'

import CustomField from '../components/CustomField/CustomField.js';
import Button from '../components/Button/Button.js';
import ToolList from '../components/ToolList/ToolList.js';

class Sketch extends Component {

  constructor(props){
    super(props);

    this.state = {
      input_string: "",
      output_string: "",
      dupe_array: []
    }

    this.handleListChange = this.handleListChange.bind(this);
  }

  handleListChange(e){

    let input = e.split(/[\,\n\r]/);

    let arr = [];
    let dupe_counters = {};
    for(let i = 0; i < input.length; i++){
      input[i] = input[i].trim();
      if(input[i] === ""){
        input.splice(i, 1);
        i--;
        continue;
      }

      let found = false;
      for(let j in arr){
        if(arr[j] == input[i]){
          found = true;
          if(!dupe_counters[input[i]]) dupe_counters[input[i]] = 0;
          dupe_counters[input[i]]++;
          break;
        }
      }
      if(!found) arr.push(input[i]);
    }

    let output_string = arr.join('\n');

    // convert dupe_counters to array;
    let dupe_array = [];
    for(let i in dupe_counters){
      dupe_array.push({
        item: i,
        counted: dupe_counters[i]
      })
    }

    dupe_array.sort((a,b)=>{
      if(a.counted > b.counted) return -1;
      if(a.counted < b.counted) return 1;
      return 0;
    })

    this.setState({
      input_string: e,
      output_string: output_string,
      dupe_array: dupe_array
    });

  }


  render(){

    return (
      <div id="remove-duplicates">
        <div className="header-bumper"/>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>remove duplicates</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CustomField
                type="text"
                name="input-list"
                placeholder="enter a comma or line separated list here..."
                minRows={15}
                multipleRows={true}
                lockSize={true}
                autoUpdate={true}
                value={this.state.input_string}
                onChangeEvent={(e) => { this.handleListChange(e); }}
                autoFocus={true}
              />
            </div>
            <div className="col-md-6">
              <CustomField
                type="text"
                name="input-list"
                placeholder="...and your de-duped list will appear here"
                minRows={15}
                lockSize={true}
                multipleRows={true}
                autoUpdate={true}
                value={this.state.output_string}
              />
            </div>
          </div>
          <div class="row">
            {
              this.state.input_string != "" &&
                <div className="col-md-12">
                  {
                    this.state.dupe_array.length > 0 ?
                    <table class="table">
                      <thead>
                        <tr>
                          <th width="80%">item</th>
                          <th width="20%">duplicates</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.dupe_array.map((d,i)=>{
                            return <tr key={i}>
                              <td>{d.item}</td>
                              <td>{d.counted}</td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                    :
                    <p>
                      No duplcates have been found 👍
                    </p>
                  }
                </div>
              }
          </div>
        </div>
        <ToolList/>
      </div>
    )
  }
}

export default Sketch;
