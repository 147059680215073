const sketches = [
  {
    id: 'random-pairs',
    description: 'randomized matches between two lists',
    date: '2019-06-07'
  },
  {
    id: 'list-randomizer',
    description: 'randomizing a list of comma or line-separated items',
    date: '2019-06-06'
  },
  {
    id: 'format-json',
    description: 'quickly make some JSON data more readable',
    date: '2020-03-20'
  },
  {
    id: 'remove-duplicates',
    description: 'find and remove duplicate entries in comma or row based data',
    date: '2020-03-20'
  },
  {
    id: 'base64',
    description: 'encode and decode base64 data',
    date: '2020-03-20'
  },
  {
    id: 'uri-encoder',
    description: 'encode and decode URI data for use within URLs',
    date: '2020-03-20'
  },
  {
    id: 'qr-code',
    description: 'create a simple QR code from text',
    date: '2020-03-20'
  },
  {
    id: 'bar-chart',
    description: 'super simple instant bar chart maker',
    date: '2020-03-20'
  },
  {
    id: 'json-to-csv',
    description: 'convert JSON arrays to comma separated values',
    date: '2020-03-20'
  },
  {
    id: 'csv-to-json',
    description: 'convert CSV data to JavaScript object notation',
    date: '2020-03-20'
  },
  {
    id: 'character-count',
    description: 'sum characters, words, and sentences in a block of text',
    date: '2020-03-20'
  }
]


export default sketches;