import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';
import $ from 'jquery'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import CustomField from '../components/CustomField/CustomField.js'
import ToolList from '../components/ToolList/ToolList.js'
 
var tools = require('../tools/_list.js').default;

class ToolRoute extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;

    return <div className="body-stretch">
      <Header/>
      <div className="header-bumper"/>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2>tools</h2>
          </div>
        </div>
      </div>
      <ToolList minimal={true}/>

      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(ToolRoute);