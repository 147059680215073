import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import jquery from 'jquery'

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    var hash = window.location.hash;

    var el;
    if(hash && this.props.location !== prevProps.location){
      el = document.getElementById(hash.replace('#',''));
      if(el){
        var top = el.getBoundingClientRect().top + window.pageYOffset;
        
        if(this.props.location.pathname === prevProps.location.pathname){
          jquery("html, body").animate({ scrollTop: top + "px" }, 1000);
        } else {
          window.scrollTo(0, top);  
        }
        
        return true;
      } 
    }

    // && this.props.location.pathname + this.props.location.hash !== prevProps.location.pathname + prevProps.location.hash

    if(!hash && this.props.location !== prevProps.location){
      window.scrollTo(0, 0);
      // console.log('scrolling to top');
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)